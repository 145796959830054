<template>
  <div class="main">
    <div class="title">系统设置 / 修改密码</div>
    <div id="static"></div>
    <div class="form">
      <p class="editpsw">修改密码</p>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          class="formItem"
          label="用户名："
          style="margin-bottom: 0px"
        >
          <span>{{ account }}</span>
        </el-form-item>
        <el-form-item
          class="formItem"
          label="手机号："
          style="margin-bottom: 10px"
        >
          <span>{{ phoneNum }}</span>
        </el-form-item>
        <el-form-item class="formItem" label="新密码：" prop="pass">
          <el-input
            type="password"
            style="width: 250px"
            v-model="ruleForm.pass"
            show-password
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="确认密码：" prop="checkPass">
          <el-input
            type="password"
            style="width: 250px"
            show-password
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="width: 250px"
            @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // 输入密码
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    // 再次输入密码
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      now: new Date(),
      data: [],
      value: Math.random() * 100,
      account: localStorage.getItem("account"),
      phoneNum: localStorage.getItem("phoneNum"),
      // 表单密码
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      // 表单规则
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.echarts();
  },
  methods: {
    // 确定按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = {
            oldPassword: localStorage.getItem("psw"),
            newPassword: this.ruleForm.pass,
            repeatPassword: this.ruleForm.checkPass,
          };
          this.$post("/user/changePassword", param)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success("修改密码成功");
                // 清除token
                window.localStorage.clear();
                // 路由跳转
                this.$router.push("/Login");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(() => {
              console.log("异常");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 随机数据
    randomData() {
      var that = this;
      that.now = new Date(+that.now + 1000);
      that.value = that.value + Math.random() * 21 - 10;
      return {
        name: that.now.toString(),
        value: [that.now, Math.round(that.value)],
      };
    },
    echarts() {
      var that = this;
      var myChart = that.$echarts.init(
        document.getElementById("static"),
        null,
        {}
      );
      setInterval(function () {
        //data.shift();
        that.data.push(that.randomData());
        myChart.setOption({
          title: {
            text: "动态数据 + 时间坐标轴",
          },
          tooltip: {
            trigger: "axis",
            // formatter: function (params) {
            //     params = params[0];
            //     // console.log(params)
            //     // var date = new Date(params.name);
            //     return  + params.value[1];
            // },
            axisPointer: {
              animation: false,
            },
          },
          xAxis: {
            type: "time",
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, "100%"],
            splitLine: {
              show: false,
            },
          },
          series: [
            {
              name: "电流",
              type: "line",
              showSymbol: false,
              hoverAnimation: false,
              smooth: true,
              data: that.data,
            },
          ],
        });
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
// 时间图表
#static {
  width: 800px;
  height: 500px;
  display: none;
  background: cadetblue;
}

.main {
  padding: 0 24px;
  .title {
    color: rgba(0, 0, 0, 0.85);
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: undefined;
    line-height: 16px;
    margin: 15px 0;
  }
  // 表单模块
  .form {
    background: #fff;
    padding: 50px 0;
    width: 100%;
    box-sizing: border-box;
    // 修改密码字样
    .editpsw {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 46.34px;
      color: rgba(33, 34, 38, 1);
      width: 100%;
      text-align: center;
    }
    .demo-ruleForm {
      width: 360px;
      margin: 20px auto;
      // background: red;
      margin-left: calc(50% -180px);
    }
  }
}
</style>
